import { FieldInputProps } from "formik";
import React, { useState } from "react";

interface IProps {
	name?: string | undefined;
	value?: string | unknown;
	label?: React.ReactNode;
	disabled?: boolean;
	style?: React.CSSProperties;
	type?: string | undefined;
	required?: boolean;
	errorMessage?: string;
	className?: string;
	onBlur?:
	| React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
	| undefined;
	onKeyPress?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
	onChange?:
	| React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
	| undefined;
	onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
	field: FieldInputProps<string>;
	isTouched: boolean | undefined;
	ref?: any;
}

const ReactInput = ({
	style,
	label,
	type,
	value,
	errorMessage,
	field,
	className,
	onFocus,
	onChange,
	onKeyPress,
	onBlur,
	isTouched,
	ref,
	...props
}: IProps) => {
	const isPassword = type === "password";
	const [showPassword, setShowpassword] = useState(false);

	const handleChange = (e: React.ChangeEvent<any>) => {
		field.onChange(e);

		if (onChange) {
			onChange(e);
		}
	};

	const handleBlur = (e: React.FocusEvent<any>) => {
		field.onBlur(e);

		if (onBlur) {
			onBlur(e);
		}
	};

	const handleClickShowPassword = () => {
		setShowpassword(!showPassword);
	};

	const errorStyle =
		errorMessage && isTouched ? { border: "1px solid #f00" } : {};

	const getInputType = () => {
		if (type === "hidden") {
			return "hidden";
		}

		if (type === "date") {
			return "date";
		}

		if (!isPassword || (isPassword && showPassword)) {
			return "text";
		}

		return "password";
	};

	return (
		<div
			className={`material-input-wrapper ${className ? className : ""}`}
			style={{ ...style }}
		>
			{isPassword && (
				<button
					type="button"
					className="show-password-button"
					tabIndex={-1}
					onClick={handleClickShowPassword}
				>
					<img
						src={showPassword ? "/dist/images/icons/ic_visibility.svg" : "/dist/images/icons/ic_visibility_off.svg"}
						alt="Passwort zeigen Icon"
					/>
				</button>
			)}
			<input
				value={value ? (value as string) : field.value}
				name={field.name}
				onChange={handleChange}
				onBlur={handleBlur}
				onKeyPress={onKeyPress}
				disabled={props.disabled}
				type={getInputType()}
				placeholder=" "
				onFocus={onFocus}
				style={errorStyle}
				ref={ref}
			/>
			<label>{label}</label>
			{isTouched && errorMessage && (
				<span
					style={{
						color: "#f00",
						display: "block",
						minHeight: 18,
						width: "100%",
						marginTop: 4,
						marginBottom: 10,
						fontSize: 12,
					}}
				>
					{errorMessage}
				</span>
			)}
		</div>
	);
};

export default ReactInput;
