import { Field, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ReactInput, ReactSelect } from "../../InputElements";
import * as Yup from "yup";
import { profileStore } from "../../stores/ProfileStore";
import SchoolInformation from "./schoolInformation";
import { updateUser } from "../../api";
import { t } from "../../utils";
import { IUserSchool } from "../../interfaces";
import { ToastStore } from "../../stores/ToastStore";
import { DateTime } from "luxon";
import LoadingSection from "./loadingSection";


interface IData {
    firstName: string;
    lastName: string;
    title: string;
    phoneNumber: string;
    schools: IUserSchool[];
    birthDate?: string;
    role: string;
}

interface ISchoolRole {
    schoolFunctionExternalId: string | null;
    schoolFunctionSubjectExternalId?: string;
}

interface IProps {
    headline: string;
    description?: string;
    isOnboarding?: boolean;
}

enum ERoleHandles {
    studentsUnder14 = "pupilUnder14",
    studentsFrom14 = "pupilFrom14",
    teacher = "teacher",
}


const PersonalInformation = (props: IProps) => {
    const [isReadOnly, setIsReadOnly] = useState(!props.isOnboarding);
    const [isLoading, setIsLoading] = useState(false);
    const [userRole, setUserRole] = useState("");

    const submit = async (values: IData) => {
        const submitData = { ...values };

        if (values.role === "pupil" && values.birthDate) {
            const birthDate = new Date(values.birthDate);

            const diff = Date.now() - birthDate.getTime();

            const ageDate = new Date(diff);

            let age = Math.abs(ageDate.getUTCFullYear() - 1970);

            if (age < 14) {
                submitData.role = ERoleHandles.studentsUnder14;
            } else {
                submitData.role = ERoleHandles.studentsFrom14;
            }
        }

        if (
            values.role === ERoleHandles.teacher &&
            values.schools
        ) {
            values.schools.forEach((school, index) => {
                if (!school.schoolCode) {
                    values.schools.splice(index, 1);
                } else {
                    school.functions.forEach((schoolFunction) => {
                        if (schoolFunction.schoolFunctionExternalId === "") {
                            schoolFunction.schoolFunctionExternalId = null;
                        }
                    });
                }
            });
        }

        setIsLoading(true);
        try {
            await updateUser(submitData);
            await profileStore.loadCurrentUser(true);

            if (!props.isOnboarding) {
                ToastStore.success(t("my_oebv.personal_settings.save_success"));
                setIsReadOnly(true);
                setIsLoading(false);
            } else if (
                !profileStore.user?.groups.includes(ERoleHandles.studentsUnder14) ||
                !profileStore.user?.groups.includes(ERoleHandles.studentsFrom14))
            {
                window.location.href = "onboarding-redirect";
            }
        } catch (error) {
            ToastStore.error(t("my_oebv.personal_settings.save_error"));
            setIsLoading(false);
        }
    };

    function onAddSchool(values: IData, setValues: any) {
        const schools = [...values.schools];
        schools.push({
            schoolCode: "",
            functions: [
                {
                    schoolFunctionExternalId: profileStore.schoolFunctions
                        ? profileStore.schoolFunctions[0]
                            ?.schoolFunctionExternalId
                        : "",
                },
            ],
        });

        setValues({ ...values, schools });
    }

    function onAddRole(values: IData, setValues: any, schoolIndex: number) {
        const schools = [...values.schools];

        if (profileStore.schoolFunctions) {
            schools[schoolIndex].functions.push({
                schoolFunctionExternalId:
                    profileStore.schoolFunctions[0].schoolFunctionExternalId,
            });
        }

        setValues({ ...values, schools });
    }

    function onRemoveSchool(
        values: IData,
        setValues: any,
        schoolIndex: number
    ) {
        const schools = [...values.schools];
        schools.splice(schoolIndex, 1);

        setValues({ ...values, schools });
    }

    function onRemoveRole(
        values: IData,
        setValues: any,
        schoolIndex: number,
        roleIndex: number
    ) {
        const schools = [...values.schools];
        const roles = [...schools[schoolIndex].functions];

        if (roles.length === 1) {
            schools[schoolIndex].functions[0].schoolFunctionExternalId = "";

            setValues({ ...values, schools });
        } else {
            roles.splice(roleIndex, 1);
            schools[schoolIndex].functions = roles;
            setValues({ ...values, schools });
        }
    }

    const schoolFunctionOptions = profileStore.schoolFunctions
        ? profileStore.schoolFunctions.map((schoolFunction) => ({
            key: schoolFunction.schoolFunctionExternalId,
            value: schoolFunction.title,
        }))
        : [];

    const schoolFunctionSubjectOptions = profileStore.schoolFunctionSubjects
        ? profileStore.schoolFunctionSubjects.map((schoolFunctionSubject) => ({
            key: schoolFunctionSubject.schoolFunctionSubjectExternalId,
            value: schoolFunctionSubject.title,
        }))
        : [];

    const hasOptionalSubject = (key: string) => {
        if (profileStore.schoolFunctions) {
            const selectedFunction = profileStore.schoolFunctions.find(
                (schoolFunction) =>
                    schoolFunction.schoolFunctionExternalId === key
            );

            if (selectedFunction) {
                return selectedFunction?.schoolFunctionIsSubjectSpecific;
            }
        }
        return false;
    };

    const getDefaultSubject = () => {
        if (profileStore.schoolFunctionSubjects) {
            return profileStore.schoolFunctionSubjects[0].schoolFunctionSubjectExternalId;
        }
    };

    const getReasonableDates = () => {
        let date = new Date();
        date.setFullYear(date.getFullYear() - 5); // pupils have to be at least 5 years old
        return date;
    }

    const handleDateSelect = (
        date: DateTime,
        setFieldValue: (
            field: string,
            value: any,
            shouldValidate?: boolean | undefined
        ) => void
    ) => {
        setFieldValue("birthDate", date, true);
    };

    const handleRoleChange = (
        formValues: React.ChangeEvent<HTMLSelectElement>,
        values: IData,
        setValues: any
    ) => {
        const selectedRole = formValues.target.value;
        const modifiedValues = { ...values };

        setUserRole(selectedRole);

        if (selectedRole === "teacher") {
            modifiedValues.role = "teacher";

            if (values.schools.length === 0) {
                onAddSchool(modifiedValues, setValues);
            }
        }
    };

    const init = async () => {
        await Promise.all([
            profileStore.loadCurrentUser(),
            profileStore.loadSchoolFunctions(),
            profileStore.loadUserGroups(),
        ]);

        setIsLoading(false);
    };

    useEffect(() => {
        setIsLoading(true);
        void init();
    }, []);

    return (
        <>
            {isLoading && profileStore.userGroupOptions.length === 0 && profileStore.user ? (
                <LoadingSection headline={props.headline} />
            ) : (
                <div className="settings-section">
                    <h3>{props.headline}</h3>
                    {props.description && <p>{props.description}</p>}
                    <Formik
                        initialValues={{
                            role: profileStore.getCurrentUserGroup(),
                            firstName: profileStore.user?.firstName || "",
                            lastName: profileStore.user?.lastName || "",
                            title: profileStore.user?.title || "",
                            phoneNumber: profileStore.user?.userPhoneNumber || "",
                            schools: profileStore.user?.userSchools || [],
                        }}
                        onSubmit={submit}
                        validationSchema={Yup.object().shape({
                            firstName: Yup.string().required(
                                t("my_oebv.personal_settings.form.first_name_required")
                            ),
                            lastName: Yup.string().required(
                                t("my_oebv.personal_settings.form.last_name_required")
                            ),
                            birthDate: userRole === "pupil" ?
                                Yup.date()
                                    .required(t("my_oebv.personal_settings.form.birthdate_required"))
                                    .max(getReasonableDates(), t("my_oebv.personal_settings.form.birthdate_error"))
                                    .min(new Date(0), t("my_oebv.personal_settings.form.birthdate_error")) :
                                Yup.string(),
                            title: Yup.string(),
                            schools: Yup.array().of(
                                Yup.object().shape({
                                    schoolNumber: Yup.string(),
                                    roles: Yup.array().of(
                                        Yup.object().shape({
                                            role: Yup.string(),
                                            subject: Yup.string(),
                                        })
                                    ),
                                })
                            ),
                        })}
                        validateOnBlur
                    >
                        {({
                            errors,
                            touched,
                            values,
                            setValues,
                            setFieldValue,
                            resetForm,
                        }) => (
                            <Form>
                                <Field
                                    component={ReactSelect}
                                    label={t("my_oebv.personal_settings.form.label.i_am")}
                                    name="role"
                                    type="text"
                                    onChange={(
                                        e: React.ChangeEvent<HTMLSelectElement>
                                    ) => handleRoleChange(e, values, setValues)}
                                    selectOptions={profileStore.userGroupOptions}
                                    disabled={
                                        !props.isOnboarding ||
                                        (props.isOnboarding &&
                                            profileStore.user
                                                ?.userVerificationStatus !==
                                            "notStarted")
                                    }
                                />
                                {values.role === "pupil" && props.isOnboarding && (
                                    <Field
                                        component={ReactInput}
                                        label="Geburtsdatum"
                                        name="birthDate"
                                        type="date"
                                        required
                                        format="MM.dd.yyyy"
                                        errorMessage={errors.birthDate}
                                        value={values.birthDate}
                                        isTouched={touched.birthDate}
                                        onDateChange={(date: DateTime) =>
                                            handleDateSelect(date, setFieldValue)
                                        }
                                    />
                                )}
                                <Field
                                    component={ReactInput}
                                    label={t(
                                        "my_oebv.personal_settings.form.label.title"
                                    )}
                                    name="title"
                                    type="text"
                                    disabled={isReadOnly}
                                    errorMessage={errors.title}
                                    isTouched={touched.title}
                                />
                                <Field
                                    component={ReactInput}
                                    label={t(
                                        "my_oebv.personal_settings.form.label.first_name"
                                    )}
                                    name="firstName"
                                    type="text"
                                    required
                                    disabled={isReadOnly}
                                    errorMessage={errors.firstName}
                                    isTouched={touched.firstName}
                                />
                                <Field
                                    component={ReactInput}
                                    label={t(
                                        "my_oebv.personal_settings.form.label.last_name"
                                    )}
                                    name="lastName"
                                    type="text"
                                    required
                                    disabled={isReadOnly}
                                    errorMessage={errors.lastName}
                                    isTouched={touched.lastName}
                                />
                                <Field
                                    component={ReactInput}
                                    label={t(
                                        "my_oebv.personal_settings.form.label.phone"
                                    )}
                                    name="phoneNumber"
                                    type="text"
                                    disabled={isReadOnly}
                                    errorMessage={errors.phoneNumber}
                                    isTouched={touched.phoneNumber}
                                />
                                {ERoleHandles.teacher === values.role && (
                                    <>
                                        <div className="spacer"></div>
                                        <h4 className="mb-4">{t("onboarding.know_each_other.schools.headline")}</h4>
                                        <FieldArray name="schools">
                                            {() => {
                                                return values.schools.map(
                                                    (school: IUserSchool, i) => {
                                                        return (
                                                            <div key={`schools-${i}`}>
                                                                {i !== 0 && (
                                                                    <div className="spacer"></div>
                                                                )}
                                                                <Field
                                                                    component={ReactInput}
                                                                    label={t(
                                                                        "my_oebv.personal_settings.form.label.school_code"
                                                                    )}
                                                                    name={`schools.${i}.schoolCode`}
                                                                    type="text"
                                                                    disabled={isReadOnly}
                                                                />
                                                                <SchoolInformation
                                                                    onRemove={() =>
                                                                        onRemoveSchool(
                                                                            values,
                                                                            setValues,
                                                                            i
                                                                        )
                                                                    }
                                                                    schoolCode={`${school.schoolCode ||
                                                                        ""
                                                                        }`}
                                                                    isDisabled={!isReadOnly}
                                                                />
                                                                <FieldArray name="schools.roles">
                                                                    {() =>
                                                                        school.functions.map(
                                                                            (role: ISchoolRole, j) => {
                                                                                return (
                                                                                    <div
                                                                                        key={`roles-${j}`}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                display:
                                                                                                    "flex",
                                                                                                flexFlow:
                                                                                                    "row",
                                                                                            }}
                                                                                        >
                                                                                            <Field
                                                                                                component={
                                                                                                    ReactSelect
                                                                                                }
                                                                                                label={t(
                                                                                                    "my_oebv.personal_settings.form.label.function"
                                                                                                )}
                                                                                                name={`schools.${i}.functions.${j}.schoolFunctionExternalId`}
                                                                                                type="text"
                                                                                                disabled={
                                                                                                    isReadOnly
                                                                                                }
                                                                                                selectOptions={[
                                                                                                    {
                                                                                                        key: "",
                                                                                                        value: "Keine Funktion",
                                                                                                    },
                                                                                                    ...schoolFunctionOptions,
                                                                                                ]}
                                                                                                onChange={(
                                                                                                    e: any
                                                                                                ) => {
                                                                                                    if (
                                                                                                        hasOptionalSubject(
                                                                                                            e.target.value
                                                                                                        )
                                                                                                    ) {
                                                                                                        setFieldValue(
                                                                                                            `schools.${i}.functions.${j}.schoolFunctionSubjectExternalId`,
                                                                                                            getDefaultSubject()
                                                                                                        );
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            {profileStore
                                                                                                .schoolFunctionHasSubjectMap[role.schoolFunctionExternalId == null ? "" : role.schoolFunctionExternalId] && (
                                                                                                    <>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                display:
                                                                                                                    "block",
                                                                                                                minWidth:
                                                                                                                    "15px",
                                                                                                            }}
                                                                                                        ></div>
                                                                                                        <Field
                                                                                                            component={
                                                                                                                ReactSelect
                                                                                                            }
                                                                                                            label={t(
                                                                                                                "my_oebv.personal_settings.form.label.function"
                                                                                                            )}
                                                                                                            name={`schools.${i}.functions.${j}.schoolFunctionSubjectExternalId`}
                                                                                                            type="text"
                                                                                                            disabled={
                                                                                                                isReadOnly
                                                                                                            }
                                                                                                            selectOptions={
                                                                                                                schoolFunctionSubjectOptions
                                                                                                            }
                                                                                                        />
                                                                                                    </>
                                                                                                )}
                                                                                        </div>
                                                                                        <div className="role-actions-container">
                                                                                            {!isReadOnly && (
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={() => {
                                                                                                        onRemoveRole(
                                                                                                            values,
                                                                                                            setValues,
                                                                                                            i,
                                                                                                            j
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    {t(
                                                                                                        "my_oebv.personal_settings.form.remove_function"
                                                                                                    )}
                                                                                                </button>
                                                                                            )}
                                                                                            {!isReadOnly &&
                                                                                                (school
                                                                                                    .functions
                                                                                                    .length ===
                                                                                                    0 ||
                                                                                                    (j ===
                                                                                                        school
                                                                                                            .functions
                                                                                                            .length -
                                                                                                        1 && (
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                onClick={() => {
                                                                                                                    onAddRole(
                                                                                                                        values,
                                                                                                                        setValues,
                                                                                                                        i
                                                                                                                    );
                                                                                                                }}
                                                                                                            >
                                                                                                                {t(
                                                                                                                    "my_oebv.personal_settings.form.add_function"
                                                                                                                )}
                                                                                                            </button>
                                                                                                        )))}
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )
                                                                    }
                                                                </FieldArray>
                                                            </div>
                                                        );
                                                    }
                                                );
                                            }}
                                        </FieldArray>
                                    </>
                                )}
                                {ERoleHandles.teacher === values.role && (
                                    <div className="spacer"></div>
                                )}
                                {!isReadOnly && (
                                    <>
                                        {ERoleHandles.teacher === values.role && (
                                            <div className="button-container">
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        onAddSchool(values, setValues);
                                                    }}
                                                >
                                                    Weitere Schule hinzufügen +
                                                </button>
                                            </div>
                                        )}
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                flexFlow: "row-reverse",
                                                marginTop: 24,
                                            }}
                                        >
                                            {isLoading ? (
                                                <button className="btn-filled loading" disabled>
                                                    <div className="btn-spinner"></div>
                                                    <span className="btn-inner"></span>
                                                </button>
                                            ) : (
                                                <button
                                                    className="button btn-filled"
                                                    type="submit"
                                                >
                                                    Speichern
                                                </button>
                                            )}
                                            {!props.isOnboarding && (
                                                <button
                                                    className="button btn-ghost"
                                                    onClick={() => {
                                                        resetForm();
                                                        setIsReadOnly(true);
                                                    }}
                                                    type="button"
                                                >
                                                    Abbrechen
                                                </button>
                                            )}
                                        </div>
                                    </>
                                )}
                            </Form>
                        )}
                    </Formik>
                    {isReadOnly && (
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <button
                                className="button edit-button"
                                onClick={() => {
                                    setIsReadOnly(false);
                                }}
                            >
                                Daten bearbeiten
                            </button>
                        </div>
                    )}
                </div>
            )}
        </>

    );
};

export default PersonalInformation;
