import { FieldInputProps } from "formik";
import React, { useEffect } from "react";
import { ISelectOption } from "../interfaces";

interface ICustomSelectInputFieldForObjects {
	label: string;
	name?: string;
	required?: boolean;
	onChange: (e: any) => void;
	selectOptions: ISelectOption[];
	disabled?: boolean;
	style?: React.CSSProperties;
	formControlStyle?: React.CSSProperties;
	value?: string;
	field: FieldInputProps<string>;
}

export const ReactSelect: React.FunctionComponent<ICustomSelectInputFieldForObjects> =
	({
		label,
		name,
		required,
		onChange,
		disabled,
		field,
		selectOptions = [],
		style,
	}) => {
		const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
			field.onChange(e);

			if (onChange) {
				onChange(e);
			}
		};

		const options = selectOptions.length
			? selectOptions.map(
				(item: { key: string; value: string; selectValue?: string }, index: number) => {
					return (
						<option key={index} value={item.selectValue ? item.selectValue : item.key}>
							{item.value}
						</option>
					);
				}
			)
			: null;

		return (
			<div className="material-input-wrapper" style={style}>
				<select
					onChange={handleChange}
					value={field.value}
					disabled={disabled || selectOptions.length === 0}
					name={field.name}
					className="material-select"
				>
					{options}
				</select>
				<label>{label}</label>
			</div>
		);
	};

export default ReactSelect;
