import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import ProfilePage from "./ProfilePage";
import { isBot } from "./utils";

// if you have a component which is not on each page available and you would like to lazy load
// check the DOM for it, then lazy load corresponding react component
// provide Suspense fallback (loading state)

export default {
	init() {

		const reactShare = document.getElementById("react-share");
		const search = document.getElementById("react-search");
		const overlay = document.getElementById("react-overlay");
		const searchButton = document.getElementById("react-search-button");
		const searchButtonMainMenu = document.getElementsByClassName(
			"main-menu-search-button"
		) as HTMLCollectionOf<HTMLElement> || null;

		const ourRowsFilter = document.getElementById("react-teaching-series-filter");
		const filterButtonContainer = document.getElementById(
			"react-filter-button-container"
		);
		const profilePage = document.getElementById("react-profile-page");
		const reactToast = document.getElementById("react-toast");
		const digitalAssetsFilter = document.getElementById(
			"react-digital-assets-filter"
		);
		const wishlist = document.getElementById("react-wishlist");
		const eventsFilter = document.getElementById("react-events-filter");
		const eventsTabs = document.getElementById("react-events-tabs");
		const reactLogin = document.getElementById("react-login");
		const verifyTeacher = document.getElementById("react-verify-teacher");
		const verifyStudent = document.getElementById("react-verify-student");
		const reactRegister = document.getElementById("react-register");
		const knowEachOther = document.getElementById("react-know-each-other");
		const schoolTypesAndSubjectSelection = document.getElementById(
			"react-schooltypes-subjects"
		);
		const resetPassword = document.getElementById("react-reset-password");
		const emailVerification = document.getElementById("react-email-verification");
		const emailVerificationFailed = document.getElementById(
			"react-email-verification-failed"
		);
		const verifyParentMail = document.getElementById("react-verify-parent-mail");
		const cart = document.getElementById("react-cart");
		const checkoutAddress = document.getElementById("react-checkout-address");
		const checkoutPaymentMethod = document.getElementById(
			"react-checkout-payment-selection"
		);
		const orderOverview = document.getElementById("react-order-overview");
		const orderSucceed = document.getElementById("react-order-succeeded");
		const licenseActivation = document.getElementById("react-license-activation");
		const newSearch = document.getElementById("react-new-search");
		const notification = document.getElementById("react-notification");

		if (resetPassword) {
			const Component = React.lazy(
				() => import("./SetPassword/SetPassword" /* webpackPrefetch: true */)
			);

			const SetPassword = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(SetPassword, resetPassword);
		}

		if (schoolTypesAndSubjectSelection) {
			const Component = React.lazy(
				() =>
					import(
						"./OnboardingSeriesSelection/OnboardingSeriesSelectionComponent" /* webpackPrefetch: true */
					)
			);
			const FilterAndSubjectSelection = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(FilterAndSubjectSelection, schoolTypesAndSubjectSelection);
		}

		if (eventsFilter) {
			const Component = React.lazy(
				() => import("./Filter/FilterComponent" /* webpackPrefetch: true */)
			);
			const Filter = (
				<Suspense fallback={null}>
					<Component
						sprigComponentId="events-pagination-component"
						settings="events"
					/>
				</Suspense>
			);
			ReactDOM.render(Filter, eventsFilter);
		}

		if (eventsTabs) {
			const Component = React.lazy(
				() => import("./EventsTabs" /* webpackPrefetch: true */)
			);
			const FilterTabs = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(FilterTabs, eventsTabs);
		}

		if (wishlist) {
			const Component = React.lazy(
				() => import("./Wishlist" /* webpackPrefetch: true */)
			);
			const Wishlist = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(Wishlist, wishlist);
		}

		if (digitalAssetsFilter) {
			const seriesSlug = digitalAssetsFilter.dataset.slug;
			const Component = React.lazy(
				() => import("./Filter/FilterComponent" /* webpackPrefetch: true */)
			);
			const Filter = (
				<Suspense fallback={null}>
					<Component
						slug={seriesSlug}
						settings="digitalAssets"
						sprigComponentId="sprig-digital-assets"
					/>
				</Suspense>
			);
			ReactDOM.render(Filter, digitalAssetsFilter);
		}

		if (overlay) {
			const Component = React.lazy(
				() => import("./Overlay" /* webpackPrefetch: true */)
			);

			const entriesRaw = overlay.dataset.entries;
			let entries = null;

			if (entriesRaw) {
				entries = JSON.parse(entriesRaw);
			}

			const Overlay = (
				<Suspense fallback={null}>
					<Component entries={entries} />
				</Suspense>
			);
			ReactDOM.render(Overlay, overlay);
		}

		if (knowEachOther) {
			const Component = React.lazy(
				() =>
					import("./KnowEachOther/KnowEachOther" /* webpackPrefetch: true */)
			);
			const KnowEachOtherComponent = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(KnowEachOtherComponent, knowEachOther);
		}

		if (reactRegister) {
			const Component = React.lazy(
				() => import("./Register/Register" /* webpackPrefetch: true */)
			);
			const Register = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(Register, reactRegister);
		}

		if (search && !isBot()) {
			const Component = React.lazy(
				() =>
					import("./SearchOverlay/SearchOverlay" /* webpackPrefetch: true */)
			);
			const Search = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(Search, search);
		}

		if (searchButton) {
			const searchText = searchButton.dataset.searchText;

			const Component = React.lazy(
				() => import("./SearchButton" /* webpackPrefetch: true */)
			);
			const Search = (
				<Suspense fallback={null}>
					<Component searchText={searchText} />
				</Suspense>
			);
			ReactDOM.render(Search, searchButton);
		}

		if (searchButtonMainMenu && searchButtonMainMenu.length > 0) {
			const buttons = Array.from(searchButtonMainMenu);

			buttons.forEach(function (button: HTMLElement) {
				const searchText = button.dataset.searchText;
				const searchTextMobile = button.dataset.searchTextMobile;

				const Component = React.lazy(
					() => import("./SearchButton" /* webpackPrefetch: true */)
				);
				const Search = (
					<Suspense fallback={null}>
						<Component searchText={searchText} searchTextMobile={searchTextMobile} />
					</Suspense>
				);
				ReactDOM.render(Search, button);
			});
		}

		if (ourRowsFilter) {
			const Component = React.lazy(
				() => import("./Filter/FilterComponent" /* webpackPrefetch: true */)
			);
			const defaultSchoolTypeRefId = ourRowsFilter.dataset.schoolType;

			const Filter = (
				<Suspense fallback={null}>
					<Component
						defaultSchoolRefId={defaultSchoolTypeRefId}
						settings="ourBooks"
						sprigComponentId="sprig-teaching-series"
					/>
				</Suspense>
			);
			ReactDOM.render(Filter, ourRowsFilter);
		}

		if (filterButtonContainer) {
			const Component = React.lazy(
				() => import("./Filter/FilterButton" /* webpackPrefetch: true */)
			);
			const FilterButton = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(FilterButton, filterButtonContainer);
		}

		if (reactToast) {
			const Component = React.lazy(
				() => import("./Toast" /* webpackPrefetch: true */)
			);
			const Toast = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(Toast, reactToast);
		}

		if (reactShare) {
			const Component = React.lazy(
				() => import("./ShareModal" /* webpackPrefetch: true */)
			);
			const ShareModal = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(ShareModal, reactShare);
		}

		if (profilePage) {
			// const Component = React.lazy(
			// 	() => import("./ProfilePage" /* webpackPrefetch: true */)
			// );
			// const Profile = (
			// 	<Suspense fallback={null}>
			// 		<Component />
			// 	</Suspense>
			// );
			ReactDOM.render(<ProfilePage />, profilePage);
		}

		if (reactLogin) {
			const Component = React.lazy(
				() => import("./Login/Login" /* webpackPrefetch: true */)
			);
			const Login = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(Login, reactLogin);
		}

		if (verifyStudent) {
			const Component = React.lazy(
				() =>
					import(
						"./VerifyTeacherEmail/VerifyTeacherEmailComponents" /* webpackPrefetch: true */
					)
			);
			const VerifyTeacher = (
				<Suspense fallback={null}>
					<Component isStudent={true} />
				</Suspense>
			);
			ReactDOM.render(VerifyTeacher, verifyStudent);
		}

		if (verifyTeacher) {
			const Component = React.lazy(
				() =>
					import(
						"./VerifyTeacherEmail/VerifyTeacherEmailComponents" /* webpackPrefetch: true */
					)
			);
			const VerifyTeacher = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);
			ReactDOM.render(VerifyTeacher, verifyTeacher);
		}

		if (emailVerification) {
			const account = emailVerification.dataset.account;
			const domain = emailVerification.dataset.domain;
			const role = emailVerification.dataset.role;

			if (account && domain) {
				const Component = React.lazy(
					() =>
						import(
							"./VerifyEmail/VerifyEmailScreen" /* webpackPrefetch: true */
						)
				);
				const VerifyEmail = (
					<Suspense fallback={null}>
						<Component account={account} domain={domain} role={role} />
					</Suspense>
				);

				ReactDOM.render(VerifyEmail, emailVerification);
			}
		}

		if (emailVerificationFailed) {
			const account = emailVerificationFailed.dataset.account;
			const domain = emailVerificationFailed.dataset.domain;
			const role = emailVerificationFailed.dataset.role;

			const Component = React.lazy(
				() =>
					import(
						"./VerifyEmailFailed/VerifyEmailFailedComponent" /* webpackPrefetch: true */
					)
			);
			const VerifyEmailFailed = (
				<Suspense fallback={null}>
					<Component account={account} domain={domain} role={role} />
				</Suspense>
			);

			ReactDOM.render(VerifyEmailFailed, emailVerificationFailed);
		}

		if (verifyParentMail) {
			const Component = React.lazy(
				() =>
					import(
						"./VerifyParentEmail/VerifyParentEmailComponents" /* webpackPrefetch: true */
					)
			);
			const VerifyParentMail = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);

			ReactDOM.render(VerifyParentMail, verifyParentMail);
		}

		if (cart) {
			const deliveryText = cart.dataset.deliveryText;
			const Component = React.lazy(
				() => import("./Cart/CartComponent" /* webpackPrefetch: true */)
			);
			const CartComponent = (
				<Suspense fallback={null}>
					<Component deliveryTime={deliveryText || ""} />
				</Suspense>
			);

			ReactDOM.render(CartComponent, cart);
		}

		if (checkoutAddress) {
			const Component = React.lazy(
				() =>
					import(
						"./CheckoutAddress/CheckoutAddress" /* webpackPrefetch: true */
					)
			);
			const CheckoutAddressComponent = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);

			ReactDOM.render(CheckoutAddressComponent, checkoutAddress);
		}

		if (checkoutPaymentMethod) {
			const action = checkoutPaymentMethod.dataset.action;
			const redirect = checkoutPaymentMethod.dataset.redirect;
			const cancelUrl = checkoutPaymentMethod.dataset.cancelUrl;
			const error = checkoutPaymentMethod.dataset.error;
			const paymentGatewaysRaw = checkoutPaymentMethod.dataset.paymentGateways;

			let paymentGateways = [];

			if (paymentGatewaysRaw) {
				paymentGateways = JSON.parse(paymentGatewaysRaw);
			}

			const Component = React.lazy(
				() =>
					import(
						"./CheckoutPaymentMethod/CheckoutPaymentMethod" /* webpackPrefetch: true */
					)
			);
			const CheckoutPaymentSelectionComponent =
				action && redirect && cancelUrl ? (
					<Suspense fallback={null}>
						<Component
							action={action}
							redirect={redirect}
							cancelUrl={cancelUrl}
							error={error}
							paymentOptions={paymentGateways}
						/>
					</Suspense>
				) : (
					<></>
				);

			ReactDOM.render(CheckoutPaymentSelectionComponent, checkoutPaymentMethod);
		}

		if (orderOverview) {
			const Component = React.lazy(
				() =>
					import(
						"./CheckoutOrderOverview/CheckoutOrderOverviewComponent" /* webpackPrefetch: true */
					)
			);
			const CheckoutOrderOverviewComponent = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);

			ReactDOM.render(CheckoutOrderOverviewComponent, orderOverview);
		}

		if (orderSucceed) {
			const deliveryText = orderSucceed.dataset.deliveryText;
			const Component = React.lazy(
				() =>
					import(
						"./CheckoutOrderSucceed/CheckoutOrderSucceed" /* webpackPrefetch: true */
					)
			);
			const CheckoutOrderSucceedComponent = (
				<Suspense fallback={null}>
					<Component deliveryTime={deliveryText || ""} />
				</Suspense>
			);

			ReactDOM.render(CheckoutOrderSucceedComponent, orderSucceed);
		}

		if (licenseActivation) {
			const headline = licenseActivation.dataset.headline ? licenseActivation.dataset.headline : null;
			const text = licenseActivation.dataset.text ? licenseActivation.dataset.text : null;

			const Component = React.lazy(
				() =>
					import(
						"./ProfilePage/digitalProducts/ActivationKeyInput" /* webpackPrefetch: true */
					)
			);
			const LicenseActivationComponent = (
				<Suspense fallback={null}>
					<Component length={12} headline={headline} text={text} isNotClosable={true} hasNoMoreInfo={true} hasNoPadding={true} isH1={true} />
				</Suspense>
			);

			ReactDOM.render(LicenseActivationComponent, licenseActivation);
		}

		if (newSearch) {
			const Component = React.lazy(
				() =>
					import(
						"./Search/SearchOverlay" /* webpackPrefetch: true */
					)
			);
			const NewSearchComponent = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);

			ReactDOM.render(NewSearchComponent, newSearch);
		}

		if (notification) {
			const Component = React.lazy(
				() =>
					import(
						"./components/Notification" /* webpackPrefetch: true */
						)
			);
			const NotificationComponent = (
				<Suspense fallback={null}>
					<Component />
				</Suspense>
			);

			ReactDOM.render(NotificationComponent, notification);
		}

	},
};